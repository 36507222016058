const translations = {
  'pt-br': {
    label_header: 'Atenção',
    label_header_error: 'Erro',
    button_no: 'Não',
    button_yes: 'Sim, fechar',
    button_ok: 'Ok',
    alert_error:
      'O chatbot ainda está processando algumas informações. Pode levar até 30 segundos. Por favor, aguarde antes de fechar.',
  },
  en: {
    label_header: 'Attention',
    label_header_error: 'Error',
    button_no: 'No',
    button_yes: 'Yes, close',
    button_ok: 'Ok',
    alert_error:
      'The chatbot is still processing some information. It may take up to 30 seconds. Please wait before closing.',
  },
  es: {
    label_header: 'Atención',
    label_header_error: 'Erro',
    button_no: 'No',
    button_yes: 'Sí, cerrar',
    button_ok: 'Ok',
    alert_error:
      'El chatbot todavía está procesando alguna información. Puede tardar hasta 30 segundos. Por favor, espere antes de cerrar.',
  },
};

const translate = (lang) =>
  new Proxy(
    {},
    {
      get(_, key) {
        const currentLang = lang?.toLowerCase();
        return (
          translations[currentLang]?.[key] || translations.en?.[key] || key
        );
      },
    }
  );

export default translate;
