import React from 'react';

import * as S from './styles';
import translate from '../../../../utils/translate';

const ContentModalError = ({ onClose, language, modalWidget = false }) => {
  const t = translate(language);
  return (
    <>
      <S.HeaderModalWarning modalWidget={modalWidget}>
        <svg viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16 3.3125L3.625 23.625C3.5 23.8125 3.5 23.9375 3.5 24.125C3.5 24.625 3.875 25 4.375 25H28.5625C29.0625 25 29.5 24.625 29.5 24.125C29.5 23.9375 29.4375 23.8125 29.3125 23.625L16.9375 3.3125C16.875 3.125 16.6875 3 16.5 3C16.25 3 16.125 3.125 16 3.3125ZM13.4375 1.75C14.0625 0.6875 15.25 0 16.5 0C17.6875 0 18.875 0.6875 19.5 1.75L31.875 22.0625C32.25 22.6875 32.5 23.375 32.5 24.125C32.5 26.25 30.75 28 28.5625 28H4.375C2.25 28 0.5 26.25 0.5 24.125C0.5 23.375 0.6875 22.6875 1.0625 22.0625L13.4375 1.75ZM18.5 21C18.5 22.125 17.5625 23 16.5 23C15.375 23 14.5 22.125 14.5 21C14.5 19.9375 15.375 19 16.5 19C17.5625 19 18.5 19.9375 18.5 21ZM18 9.5V15.5C18 16.375 17.3125 17 16.5 17C15.625 17 15 16.375 15 15.5V9.5C15 8.6875 15.625 8 16.5 8C17.3125 8 18 8.6875 18 9.5Z"
            fill="#9E2121"
          />
        </svg>
      </S.HeaderModalWarning>
      <S.ContentModal modalWidget={modalWidget}>{t.alert_error}</S.ContentModal>
      <S.FooterModal>
        <S.CustomButton onClick={onClose}>{t.button_ok}</S.CustomButton>
      </S.FooterModal>
    </>
  );
};

export default ContentModalError;
