import React from 'react';

import ContentModal from '../ContentModal';
import * as S from './styles';

export function AlertWidget({
  alertMessage,
  isOpen,
  onClose,
  onCloseAction,
  isLoadCloseAction,
}) {
  return (
    <S.BackgroundModalWidget isOpen={isOpen}>
      <S.ModalWidget isOpen={isOpen} onClose={onClose}>
        <ContentModal
          alertMessage={alertMessage}
          onClose={onClose}
          onCloseAction={onCloseAction}
          isLoadCloseAction={isLoadCloseAction}
          modalWidget
        />
      </S.ModalWidget>
    </S.BackgroundModalWidget>
  );
}
