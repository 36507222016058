export const setCloseAction = async ({ closeAction, botId, sessionId }) => {
  let closeActionResponse;
  const ORQUESTRATOR_URL = process.env.REACT_APP_API_URL;

  if (ORQUESTRATOR_URL) {
    const url = `${ORQUESTRATOR_URL}/user-action/${closeAction}`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ botId, sessionId }),
      });

      const data = await response.json();
      if (!response.ok) {
        console.error(`Request failed with status ${response.status}`);
        return { status: response.status, message: data?.message?.error };
      }

      return { ...data, status: response.status };
    } catch (error) {
      console.error('Error CloseAction:', error);
      return null;
    }
  }
  return closeActionResponse;
};
