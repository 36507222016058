import styled from 'styled-components';
import Button from '../../../Button';

export const HeaderModalWarning = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 12px;
  border-radius: 5px;
  width: ${({ modalWidget }) => (modalWidget ? '36px' : '56px')};
  background: rgb(250, 224, 210);
  margin: 16px auto;
`;

export const ContentModal = styled.div`
  min-height: ${({ modalWidget }) => (modalWidget ? '22px' : '50px')};
  padding: 16px;
  overflow-y: auto;
  position: relative;
  font-weight: 600;
  text-align: center;
`;

export const FooterModal = styled.div`
  padding: 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: space-around;
  gap: 8px;
`;

export const CustomButton = styled(Button)`
  width: 100%;
  border-radius: 10px;

  border: ${(props) =>
    props.variant === 'ghost' ? '1px solid #979AA5 !important' : 'none'};
  color: ${(props) =>
    props.variant === 'ghost' ? '#5A5D68 !important' : 'inherit'};
`;
