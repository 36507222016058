import styled from 'styled-components';
import Modal from '../Modal';

export const CustomModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  background-color: white;
  min-width: 300px;
  min-height: 200px;
  max-height: 80vh;
  overflow: hidden;
  border-radius: 16px;
`;
