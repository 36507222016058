import styled from 'styled-components';

export const BackgroundModalWidget = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  cursor: ${({ isFixed }) => (isFixed === 0 ? 'pointer' : 'default')};

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  justify-content: center;
  align-items: center;
  z-index: ${({ zIndex }) => zIndex};
  animation: fadeIn 380ms ease-in-out 1;
  background-color: #2f3845b8;
  backdrop-filter: blur(2px);
  border-radius: 17px;

  @keyframes fadeIn {
    from {
      background-color: #2f384521;
    }
    to {
      background-color: #2f3845b8;
    }
  }
`;
export const ModalWidget = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  min-width: 300px;
  min-height: 200px;
  max-height: 80vh;
  overflow: hidden;
  border-radius: 16px;
  margin: 16px;
`;
