import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const Modal = ({
  isOpen,
  maxWidth,
  maxHeight,
  className,
  children,
  onClose,
  isFixed,
  zIndex,
}) => {
  const modalRef = useRef();

  const handleKeyUp = useCallback(
    (e) => {
      const keys = {
        // ESC Key
        27: () => {
          e.preventDefault();
          if (!isFixed) {
            onClose?.();
          }
          window.removeEventListener('keyup', handleKeyUp, false);
        },
      };

      if (keys[e.keyCode] && isOpen) {
        keys[e.keyCode]();
      }
    },
    [onClose, isOpen]
  );

  const handleOutsideClick = useCallback(
    (e) => {
      if (modalRef.current.parentNode === e.target) {
        if (!isFixed) {
          onClose?.();
        }
        document.removeEventListener('click', handleOutsideClick, false);
      }
    },
    [onClose]
  );

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp, false);
    document.addEventListener('click', handleOutsideClick, false);

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, [handleKeyUp, handleOutsideClick]);

  return (
    <S.FullScreen
      isFixed={isFixed ? 1 : 0}
      isOpen={isOpen ? 1 : 0}
      zIndex={zIndex}
    >
      <S.Modal ref={modalRef} maxWidth={maxWidth} className={className}>
        <S.ModalContainer maxHeight={maxHeight}>{children}</S.ModalContainer>
      </S.Modal>
    </S.FullScreen>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  isFixed: PropTypes.bool,
  zIndex: PropTypes.number,
};

Modal.defaultProps = {
  maxWidth: '360px',
  maxHeight: 'max-content',
  isFixed: false,
  onClose: () => {},
  zIndex: 9999,
};

export default Modal;
