import React from 'react';

import ContentModal from './components/ContentModal';

import * as S from './styles';

export { AlertWidget } from './components/AlertWidget';

export default function ModalAlertCloseable({
  alertMessage,
  isOpen,
  onClose,
  onCloseAction,
  isLoadCloseAction,
}) {
  return (
    <S.CustomModal isOpen={isOpen} onClose={!isLoadCloseAction && onClose}>
      <ContentModal
        alertMessage={alertMessage}
        onClose={onClose}
        onCloseAction={onCloseAction}
        isLoadCloseAction={isLoadCloseAction}
      />
    </S.CustomModal>
  );
}
