import React from 'react';

import ContentModal from './components/ContentModal';

import * as S from './styles';

export { AlertWidget } from './components/AlertWidget';

export default function ModalAlertCloseableError({
  language,
  isOpen,
  onClose,
}) {
  return (
    <S.CustomModal isOpen={isOpen} onClose={onClose}>
      <ContentModal language={language} onClose={onClose} />
    </S.CustomModal>
  );
}
