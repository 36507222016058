import React, { useState, useContext } from 'react';

import { SettingsContext } from '../../Context/SettingsContext';
import { ChatContext } from '../../Context/ChatContext';
import { MessengerContext } from '../../Context/MessengerContext';

import Modal from '../Modal';
import Messages from '../Messages';
import Settings from '../Settings';
import InteractionForm from '../InteractionForm';
import Header from '../Header';
import Footer from '../Footer';
import * as S from './styles';
import { AlertWidget } from '../ModalAlertCloseable';
import { AlertWidget as AlertWidgetError } from '../ModalAlertCloseableError';

const WidgetChat = ({ webchatIsOpen, setWebchatIsOpen, webchatIsStarted }) => {
  const [settingsIsOpen, setSettingsIsOpen] = useState(false);

  const { bot, customSettings } = useContext(SettingsContext);
  const { channelsBar, channel } = bot;
  const { isChannelsBar } = customSettings;
  const iconSize = isChannelsBar
    ? channelsBar?.settings?.iconSize
    : channel?.settings.iconSize;

  const {
    messageListRef,
    messages,
    imgSrc,
    imgTitle,
    fullScreen,
    historicMessages,
    isOpenModal,
    setIsOpenModal,
    onCloseAction,
    webchatCloseActionError,
    isLoadCloseAction,
  } = useContext(ChatContext);

  const {
    handleClickImage,
    handleQuickAccessClick,
    handleCarouselButtonClick,
    handleLoadPreviousMessages,
    handleSubmitMessage,
    handleReset,
  } = useContext(MessengerContext);

  const webchatActions = bot?.webchatActions;

  const { webchatType } = customSettings;

  const handleModalClose = () => {
    setIsOpenModal(false);
  };

  const handleCloseAction = () => {
    onCloseAction({ webchatActions, callback: () => handleReset(false) });
  };

  return (
    <>
      {webchatIsStarted && (
        <S.Container iconSize={+iconSize} isChannelsBar={isChannelsBar}>
          <div
            className={
              webchatIsOpen
                ? 'webchat_fixed webchat_opened'
                : 'webchat_fixed webchat_closed'
            }
            style={{ position: 'relative' }}
          >
            <Header
              settingsIsOpen={settingsIsOpen}
              setSettingsIsOpen={setSettingsIsOpen}
              setWebchatIsOpen={setWebchatIsOpen}
              handleReset={handleReset}
            />
            {settingsIsOpen ? (
              <Settings setSettingsIsOpen={setSettingsIsOpen} />
            ) : (
              <Messages
                ref={messageListRef}
                messages={messages}
                historicMessages={historicMessages}
                onCarouselButtonClick={handleCarouselButtonClick}
                channel="webchat"
                handleClickImage={handleClickImage}
                handleLoadPreviousMessages={handleLoadPreviousMessages}
                onSuggestionClick={handleSubmitMessage}
              />
            )}
            <InteractionForm
              onSubmit={handleSubmitMessage}
              quickAccess={bot?.quickAccess}
              onQuickAccessClick={handleQuickAccessClick}
            />
            <Footer />
            <Modal
              maxWidth="fit-content"
              isOpen={fullScreen}
              onClose={() => handleClickImage(false, false)}
              showHeader={false}
              className="modal"
            >
              {imgSrc && (
                <S.ImageFull
                  src={imgSrc}
                  alt={imgTitle}
                  display={fullScreen ? 'inline' : 'none'}
                  onClick={() => handleClickImage(false, false)}
                />
              )}
            </Modal>
            {webchatType !== 'fullscreen' && (
              <>
                <AlertWidget
                  alertMessage={webchatActions?.alertMessage}
                  isOpen={isOpenModal}
                  onClose={handleModalClose}
                  onCloseAction={handleCloseAction}
                  isLoadCloseAction={isLoadCloseAction}
                />
                {webchatCloseActionError && (
                  <AlertWidgetError
                    language={webchatActions.alertMessage.language}
                    isOpen={isOpenModal}
                    onClose={handleModalClose}
                  />
                )}
              </>
            )}
          </div>
        </S.Container>
      )}
    </>
  );
};

export default WidgetChat;
