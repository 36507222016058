import { useContext } from 'react';
import { SettingsContext } from '../Context/SettingsContext';
import { CLOSE_ACTION_KEY_STORAGE } from './constants';

const LocalStorageUtils = () => {
  const { bot, customSettings } = useContext(SettingsContext);
  const { webchatType } = customSettings;
  const { _id: botId } = bot;

  const setOnLocalStorage = (module, data, currentBotId = botId) => {
    if (webchatType === 'fullscreen') return;
    return localStorage.setItem(`boteria-${module}-${currentBotId}`, data);
  };

  const getOnLocalStorage = (module, currentBotId = botId) => {
    if (webchatType === 'fullscreen') return;
    return localStorage.getItem(`boteria-${module}-${currentBotId}`);
  };

  const removeOnLocalStorage = (modules, currentBotId = botId) => {
    if (webchatType === 'fullscreen') return;
    const arrModules = Array.isArray(modules) ? modules : [modules];
    return arrModules.forEach((module) => {
      localStorage.removeItem(`boteria-${module}-${currentBotId}`);
    });
  };

  const removeBotIdFromCloseActions = (currentBotId = botId) => {
    const closeActionsBots = localStorage.getItem(CLOSE_ACTION_KEY_STORAGE);
    const actionsArray = closeActionsBots ? JSON.parse(closeActionsBots) : [];
    const updatedActions = actionsArray?.filter(
      (action) => action !== currentBotId
    );
    localStorage.setItem(
      CLOSE_ACTION_KEY_STORAGE,
      JSON.stringify(updatedActions)
    );
  };

  return {
    setOnLocalStorage,
    getOnLocalStorage,
    removeOnLocalStorage,
    removeBotIdFromCloseActions,
  };
};

export default LocalStorageUtils;
