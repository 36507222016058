import React from 'react';

import ContentModal from '../ContentModal';
import * as S from './styles';

export function AlertWidget({ language, isOpen, onClose, onCloseAction }) {
  return (
    <S.BackgroundModalWidget isOpen={isOpen}>
      <S.ModalWidget isOpen={isOpen} onClose={onClose}>
        <ContentModal
          language={language}
          onClose={onClose}
          onCloseAction={onCloseAction}
          modalWidget
        />
      </S.ModalWidget>
    </S.BackgroundModalWidget>
  );
}
